<template>
  <div class="boxTop">
    <div class="top">
      <img src="@/assets/image/logo.png" alt="logo" />
      <div style="display: inline; float: right">
        <div style="max-width: 730px">
          <div class="button_import">
            <img src="@/assets/image/download.png" alt="" />
            <a href="https://ptgresource.s3.amazonaws.com/anyconnect-macos-4.10.08029-predeploy-k9.dmg">AnyConnect For
              Mac</a>
          </div>
          <div class="button_import" style="margin-left: 30px">
            <img src="@/assets/image/download.png" alt="" />
            <a href="https://ptgresource.s3.amazonaws.com/anyconnect-win-4.10.04065.msi">AnyConnect For Win</a>
          </div>
          <div class="button_import" style="margin-left: 30px">
            <img src="@/assets/image/download.png" alt="" />
            <a href="https://ptgresource.s3.us-east-1.amazonaws.com/anyconnect-win-arm64-4.10.08029-core-vpn-predeploy-k9.msi">AnyConnect For WinARM</a>
          </div>
        </div>

        <div style="max-width: 730px">
          <div class="button_instruction">
            <img src="@/assets/image/instruction.png" alt="" />
            <a href="static/mac.html" target="_blank">Instructions</a>
          </div>
          <div class="button_instruction" style="margin-left: 30px">
            <img src="@/assets/image/instruction.png" alt="" />
            <a href="static/win.html" target="_blank">Instructions</a>
          </div>
          <div class="button_instruction" style="margin-left: 30px">
            <img src="@/assets/image/instruction.png" alt="" />
            <a href="static/winARM.html" target="_blank">Instructions</a>
          </div>
        </div>
      </div>
    </div>
    <!--<div style="display: inline;float:right; margin-right: 38px;">
            <div class="button_import" style="margin-top: 56px;border-radius: 5px; ">
                <img src="@/assets/image/download.png" alt="">
                <a href="https://ptgresource.s3.amazonaws.com/Re-connect_to_email_account_in_PC_outlook.docx">Reconnet Email</a>
            </div>
          </div>-->
    </div>
    <div class="content">
      <div class="tab">
        <p style="margin-top: 10px">AWS Virginia Region(United States)</p>
        <ul>
          <li style="height: 140px">
            <a
              ref="usmis"
              :href="isUSConnected ? 'https://usmis.ptglab.com/' : ''"
              :target="isUSConnected ? '_blank' : ''"
              @click="onConnectClick('usmis')"
            >
              <img
                ref="usmis_img"
                :src="
                  isMounted
                    ? isUSConnected
                      ? require('@/assets/image/us.jpg')
                      : require('@/assets/image/us_h.jpg')
                    : ''
                "
              />
            </a>
            <P style="bottom: 25px"> US mgmt </P>
            <div>
              <a href="http://10.108.3.240" target="_blank"
                >Alternative Link
              </a>
            </div>
          </li>
          <li style="height: 140px">
            <a
              ref="sgmis"
              :href="isUSConnected ? 'https://sgmis.ptglab.com/' : ''"
              :target="isUSConnected ? '_blank' : ''"
              @click="onConnectClick('sgmis')"
            >
              <img
                ref="sgmis_img"
                :src="
                  isMounted
                    ? isUSConnected
                      ? require('@/assets/image/sg.jpg')
                      : require('@/assets/image/sg_h.jpg')
                    : ''
                "
              />
            </a>
            <P style="bottom: 25px"> SG mgmt </P>
            <div>
              <a href="http://10.108.3.235" target="_blank"
                >Alternative Link
              </a>
            </div>
          </li>
          <li>
            <a
              ref="ptglab"
              :href="isMounted ? 'https://www.ptglab.com/' : ''"
              :target="isMounted ? '_blank' : ''"
            >
              <img
                ref="ptglab_img"
                :src="isMounted ? require('@/assets/image/ptglab.jpg') : ''"
              />
            </a>
            <P> ptglab </P>
          </li>
          <li>
            <a
              ref="ptglabJP"
              :href="isMounted ? 'https://www.ptglab.co.jp/' : ''"
              :target="isMounted ? '_blank' : ''"
            >
              <img
                ref="ptglabJP_img"
                :src="isMounted ? require('@/assets/image/jp.jpg') : ''"
              />
            </a>
            <P> ptglab.jp </P>
          </li>
        </ul>
      </div>
      <div class="tab">
        <p>AWS Frankfurt Region(Europe)</p>
        <ul>
          <li style="height: 140px">
            <a
              ref="demis"
              :href="isEUConnected ? 'https://demis.ptglab.com/' : ''"
              :target="isEUConnected ? '_blank' : ''"
              @click="onConnectClick('demis')"
            >
              <img
                ref="demis_img"
                :src="
                  isMounted
                    ? isEUConnected
                      ? require('@/assets/image/de.jpg')
                      : require('@/assets/image/de_h.jpg')
                    : ''
                "
              />
            </a>
            <P style="bottom: 25px"> DE mgmt </P>
            <div>
              <a href="http://10.107.3.100" target="_blank"
                >Alternative Link
              </a>
            </div>
          </li>
          <li style="height: 140px">
            <a
              ref="eumis"
              :href="isEUConnected ? 'https://eumis.ptglab.com/' : ''"
              :target="isEUConnected ? '_blank' : ''"
              @click="onConnectClick('eumis')"
            >
              <img
                ref="eumis_img"
                :src="
                  isMounted
                    ? isEUConnected
                      ? require('@/assets/image/eu.jpg')
                      : require('@/assets/image/eu_h.jpg')
                    : ''
                "
              />
            </a>
            <P style="bottom: 25px"> UK mgmt </P>
            <div>
              <a href="http://10.107.3.27" target="_blank">Alternative Link </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="tab">
        <p>AWS Ningxia Region(China)</p>
        <ul>
          <li style="height: 140px">
            <a
              ref="ptgmis"
              :href="isCNConnected ? 'https://pms.ptgcn.com/' : ''"
              :target="isCNConnected ? '_blank' : ''"
              @click="onConnectClick('ptgmis')"
            >
              <img
                ref="ptgmis_img"
                :src="
                  isMounted
                    ? isCNConnected
                      ? require('@/assets/image/ptgmis.jpg')
                      : require('@/assets/image/ptgmis_h.jpg')
                    : ''
                "
              />
            </a>
            <P style="bottom: 25px; height: 40px">
              Wuhan Lab system<br />
              三鹰生产系统
            </P>
            <div>
              <a href="http://10.106.3.166" target="_blank"
                >Alternative Link
              </a>
            </div>
          </li>
          <li>
            <a
              ref="CNSales"
              href='http://10.106.3.201/'
              target='_blank'
            >
              <img
                ref="CNSales_img"
                :src="
                  isMounted
                    ? require('@/assets/image/cn.jpg')
                    : ''
                "
              />
            </a>
            <P> 中国销售 </P>
          </li>
          <li>
            <a
              ref="SHSales"
              href='http://10.106.3.202/'
              target='_blank'
            >
              <img
                ref="SHSales_img"
                :src="
                  isMounted
                    ? require('@/assets/image/sh.jpg')
                    :''"
              />
            </a>
            <P> 上海销售 </P>
          </li>
        </ul>
      </div>
      <div class="tab">
        <p>Wuhan IDC Region</p>
        <ul>
          <li>
            <a
              ref="PMSSandbox"
              :href="isMounted ? 'http://192.168.1.198:5300/' : ''"
              :target="isMounted ? '_blank' : ''"
            >
              <img
                ref="PMSSandbox_img"
                :src="isMounted ? require('@/assets/image/ptgmis.jpg') : ''"
              />
            </a>
            <P style="height: 40px">
              Wuhan Lab system training<br />
              生产系统培训
            </P>
          </li>
        </ul>
      </div>
      <div class="tab">
        <p>Chicago IDC Region</p>
        <ul>
          <li>
            <a
              ref="chicagoIDC"
              :href="isUSConnected ? 'http://192.168.100.241/' : ''"
              :target="isUSConnected ? '_blank' : ''"
              @click="onConnectClick('chicagoIDC')"
            >
              <img
                ref="chicagoIDC_img"
                :src="
                  isMounted
                    ? isUSConnected
                      ? require('@/assets/image/ws.jpg')
                      : require('@/assets/image/ws_h.jpg')
                    : ''
                "
              />
            </a>
            <P> Web Staging </P>
          </li>
          <li>
            <a
              ref="CTKOldWeb"
              :href="isUSConnected ? 'http://192.168.100.241:8088/' : ''"
              :target="isUSConnected ? '_blank' : ''"
            >
              <img
                ref="CTKOldWeb_img"
                :src="
                  isMounted
                    ? isUSConnected
                      ? require('@/assets/image/ctk.jpg')
                      : require('@/assets/image/ctk_h.jpg')
                    : ''
                "
              />
            </a>
            <P> CTK old web </P>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";

export default {
  name: "PortalPage",
  props: {},
  setup() {
    let isMounted = ref(false);
    let isUSConnected = ref(false);
    let isEUConnected = ref(false);
    let isCNConnected = ref(false);

    const instance = getCurrentInstance();
    // const { $axios } = currentInstance.appContext.config.globalProperties;

    /**
     * 轮询调用不同地区网络健康检查接口
     */
    function connectAction() {
      const checkHealth = (url, connectedState) => {
        return instance.proxy
          .$axios({
            method: "get",
            url: url,
          })
          .then((response) => {
            // console.log(`Connected to ${url}`);
            connectedState.value = response.status === 200;
          })
          .catch(() => {
            // console.error(`Error checking health for ${url}: ${error}`);
            connectedState.value = false;
          });
      };

      /**
       * 并行请求调用
       */
      const Connected = async () => {
        await Promise.all([
          checkHealth(
            "https://usmis.ptglab.com/Home/CheckHealth",
            isUSConnected
          ),
          checkHealth(
            "https://eumis.ptglab.com/Home/CheckHealth",
            isEUConnected
          ),
          checkHealth("https://pms.ptgcn.com/Home/CheckHealth", isCNConnected),
        ])
          .then(() => {
            setTimeout(
              Connected,
              isUSConnected.value || isEUConnected.value || isCNConnected.value
                ? 20000
                : 2000
            );
          })
          .catch(() => {
            // console.error("Health check failed:", error);
            setTimeout(Connected, 2000); // 如果有错误发生，等待 2 秒后重试
          });
      };

      Connected();
    }

    function onConnectClick(id) {
      const usConnectId = ["usmis", "chicagoIDC", "sgmis"];
      const euConnectId = ["demis", "eumis"];
      const cnConnectId = ["ptgmis"];
      const showAlert = () => {
        alert(
          "This site is not accessible currently. Please check your VPN connection, if you are not in proteintech offices."
        );
      };

      if (usConnectId.includes(id) && !isUSConnected.value) {
        showAlert();
      }

      if (euConnectId.includes(id) && !isEUConnected.value) {
        showAlert();
      }

      if (cnConnectId.includes(id) && !isCNConnected.value) {
        showAlert();
      }
    }

    /**
     * dom挂载完毕
     */
    onMounted(() => {
      isMounted.value = true;
      connectAction();
    });
    return {
      isMounted,
      isUSConnected,
      isEUConnected,
      isCNConnected,
      connectAction,
      onConnectClick,
    };
  },
};
</script>

<style scoped>
.boxTop {
  background: #004271;
  height: 90px;
  width: 100%;
  position: relative;
}

.top {
  width: 1000px;
  height: 100%;
  margin: 0 auto;
}

.top > img {
  width: 190px;
  margin-top: 10px;
  float: left;
}

.content {
  width: 1100px;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
}

.tab {
  padding: 10px;
  padding-left: 50px;
}

.tab > p {
  height: 14px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 20px 20px 0px;
}

.tab ul {
  width: 100%;
  height: 150px;
  display: flex;
}

.tab ul li {
  width: 220px;
  height: 115px;
  border-radius: 5px;
  margin: 0px 30px 10px 0;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 15px 10px -15px #000;
}

.tab ul li img {
  width: 220px;
  transition: all 0.5s;
}

.tab ul li p {
  width: 220px;
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 25px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 8px;
  color: #fff;
  font-size: 14px;
}

.tab ul li div {
  width: 220px;
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 20px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding-top: 8px;
  color: #fff;
  font-size: 14px;
  color: #004271;
  z-index: 999;
}

.tab ul li div a {
  color: #004271;
}

.tab ul li:hover img {
  transform: scale(1.2);
}

.tab ul li:hover .overlay {
  opacity: 1;
}

.top .button_import {
  width: 200px;
  height: auto;
  border-radius: 5px 5px 0 0;
  /*box-shadow: 0px 2px 2px 0px #000;*/
  background-image: linear-gradient(#fff, #c9c7c7);
  display: inline-block;
  text-align: left;
  margin-top: 30px;
  padding: 5px 10px;
  overflow: hidden;
  cursor: pointer;
}

.top .button_import a {
  color: #000;
  text-decoration: none;
  float: left;
  font-weight: bold;
}

.top .button_import img {
  width: 16px;
  float: left;
  margin-right: 8px;
}

.button_instruction {
  width: 200px;
  height: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 2px 0px #000;
  background-image: linear-gradient(#fff, #c9c7c7);
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  overflow: hidden;
  cursor: pointer;
  margin-top: -3px;
  /*margin-left:50px;*/
}

.top .button_instruction a {
  color: #000;
  text-decoration: none;
  float: left;
  font-weight: bold;
  display: inline-block;
  width: 145px;
}

.top .button_instruction img {
  width: 16px;
  float: left;
  margin-right: 8px;
}
</style>